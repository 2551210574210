import React from 'react';

const EmergencyTextWithDescription = ({ title, description }) => {
  return (
    <div className="emergency-text-with-description__container">
      <h3 className="emergency-text-with-description__title">{title}</h3>
      {description && <p className="emergency-text-with-description__description">{description}</p>}
    </div>
  );
};

export default EmergencyTextWithDescription;
