import React from 'react';
import Img from 'gatsby-image';
import EmergencyTextWithDescription from '../EmergencyTextWithDescription';

const EmergencyTextWithImage = ({
  title,
  description,
  image,
  caption,
  date
}) => {
  return (
    <div className="emergency-text-with-image__grid">
      <div className="emergency-text-with-image__wrapper">
        <div className="emergency-text-with-image__text-container">
          <EmergencyTextWithDescription title={title} description={description} />
        </div>
        {
          image && (
            <div className="emergency-text-with-image__photo-container">
              <Img
                fluid={{ ...image.fluid, aspectRatio: 1.77 }}
                className="emergency-text-with-image__photo"
                alt={image.alt}
              />
              {caption && <div className="emergency-text-with-image__caption">{caption}{date && <><br /><span className="emergency-text-with-image__date">{date.split('/').join(' ')}</span></>}</div>}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default EmergencyTextWithImage;
