/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../containers/layout';
import SliderHero from '../components/SliderHero';
import ListCardsImage from '../components/ListCardsImage';
import Feed from '../components/Feed';
import CardHero from '../components/CardHero';
import VisualComposer from '../components/VisualComposer';
import StructuredData from '../components/StructuredData';
import EmergencyVerticalSlider from '../components/EmergencyVerticalSlider';

const IndexPage = ({ data }) => {
  const { newsFeedModularBlock, seoMetaTags, canonical } = data.datoCmsHome; // Feed
  const { datoCmsCommunicationBar } = data; // Feed
  const { newsHighlight } = newsFeedModularBlock[0] ? newsFeedModularBlock[0] : {}; // CardHero
  const { slug } = data.datoCmsNewsArchive;
  const { sliderHero } = data.datoCmsHome;
  const { defaultRelatedNews } = { defaultRelatedNews: data.allDatoCmsNews.nodes };

  const { emergencyModelLink: emergencyModel } = data.datoCmsEmergenzaLandingPage || {};

  const cardHeroProps = {
    label: newsHighlight ? newsHighlight.topic.name : '',
    title: newsHighlight ? newsHighlight.title : '',
    labelButton: 'Scopri di più',
    image: (newsHighlight.coverImage && newsHighlight.coverImage.fluid) ? newsHighlight.coverImage.fluid : '',
    alt: (newsHighlight.coverImage && newsHighlight.coverImage.alt) ? newsHighlight.coverImage.alt : '',
    url: newsHighlight ? `/${slug}/${newsHighlight.slug}` : ''
  };

  const feedProps = {
    title: 'home',
    url: slug,
    withHighlight: !!newsHighlight,
    labelLink: 'Vedi tutte',
    hasBackground: true,
    hasPadding: true,
    marginTop: 'zero',
    marginBottom: 'zero'
  };

  const wrappedFeedProps = {
    feedProps,
    cardHeroProps,
    newsHighlight,
    newsFeedModularBlock,
    defaultRelatedNews
  };

  return (
    <Layout
      idEmergenzaLanding={emergencyModel?.emergencyWidgetId}
      seoMetaTags={seoMetaTags}
      pathname="/"
      communicationBar={datoCmsCommunicationBar}
      pageType="Homepage"
      canonical={canonical || 'https://www.unicef.it/'}
    >
      <StructuredData>
        {{
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: 'https://www.google.com/',
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: 'https://www.google.com/search?q=unicef.it+{search_term_string}'
            },
            'query-input': 'required name=search_term_string'
          }
        }}
      </StructuredData>
      {emergencyModel && (
        <EmergencyVerticalSlider
          colorTheme={emergencyModel.emergencyColorTheme}
          hasForm={emergencyModel.emergencyForm}
          stripe={emergencyModel.emergencyStripeTitle}
          hero={{
            title: emergencyModel.heroTitleDesktop,
            titleMobile: emergencyModel.heroTitleMobile,
            titlePosition: emergencyModel.heroTitlePosition,
            titleSize: emergencyModel.heroTitleSize,
            image: emergencyModel.heroImageDesktop,
            imageMobile: emergencyModel.heroImageMobile
          }}
          modularContent={emergencyModel.modularContent}
        />
      )}
      {sliderHero && <SliderHero slides={sliderHero} />}
      {
        data.datoCmsHome.visualComposerTop && (
          <VisualComposer
            components={data.datoCmsHome.visualComposerTop}
          />
        )
      }
      {newsFeedModularBlock[0] && <FeedContainer {...wrappedFeedProps} />}
      {
        data.datoCmsHome.visualComposerBottom && (
          <VisualComposer
            components={data.datoCmsHome.visualComposerBottom}
          />
        )
      }
    </Layout>
  );
};

const FeedContainer = ({
  feedProps,
  cardHeroProps,
  newsHighlight,
  newsFeedModularBlock,
  defaultRelatedNews
}) => {
  const [cards, setCards] = useState();
  const post = newsFeedModularBlock[0] ? newsFeedModularBlock[0].post : [];

  useEffect(() => {
    if (post.length > 0) { // At least 1 custom post
      if (newsHighlight) {
        const newCards = [newsHighlight, ...post];
        setCards(newCards);
      } else {
        setCards(post);
      }
    } else { // Mount the default related news
      if (newsHighlight) {
        const newCards = [newsHighlight, ...defaultRelatedNews];
        setCards(newCards);
      } else {
        setCards(defaultRelatedNews);
      }
    }
  }, []);

  return (
    <Feed {...feedProps}>
      {newsHighlight && <CardHero {...cardHeroProps} />}
      <ListCardsImage isFeed cards={cards} />
    </Feed>
  );
};

export default IndexPage;

export const HOME_QUERY = graphql`
  query HomeQuery {
    datoCmsEmergenzaLandingPage {
      emergencyModelLink {
        emergencyWidgetId
        id
        emergencyColorTheme
        emergencyForm
        emergencyStripeTitle
        heroImageDesktop {
          fluid {
            src
            srcSet
            aspectRatio
            sizes
            width
            height
          }
        }
        heroImageMobile {
          fluid {
            src
            srcSet
            aspectRatio
            sizes
            width
            height
          }
        }
        heroTitleDesktop
        heroTitleMobile
        heroTitlePosition
        heroTitleSize
        modularContent {
          ... on DatoCmsEmergencyTextWithImageBlock {
            id
            title
            description
            image {
              fluid {
                src
                srcSet
                aspectRatio
                sizes
                width
                height
              }
            }
            caption
            date(formatString: "D MMM, YYYY", locale: "it")
            model {
              apiKey
            }
          }
          ... on DatoCmsEmergencyTextWithGalleryBlock {
            id
            title
            description
            imageGallery {
              fluid {
                src
                srcSet
                aspectRatio
                sizes
                width
                height
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsEmergencyTextWithVideoBlock {
            model {
              apiKey
            }
            id
            title
            description
            video {
              providerUid
            }
            previewImage {
              fluid {
                src
                srcSet
                aspectRatio
                sizes
                width
                height
              }
            }
            caption
            date(formatString: "D MMM, YYYY", locale: "it")
          }
          ... on DatoCmsEmergencyTextWithAudioBlock {
            model {
              apiKey
            }
            id
            title
            description
            audio {
              url
            }
            caption
            date(formatString: "D MMM, YYYY", locale: "it")
          }
          ... on DatoCmsEmergenzaQuote {
            model {
              apiKey
            }
            id
            title
            description
            imageDesktop {
              fluid {
                src
                srcSet
                aspectRatio
                sizes
                width
                height
              }
            }
            imageMobile {
              fluid {
                src
                srcSet
                aspectRatio
                sizes
                width
                height
              }
            }
          }
        }
      }
    }
    datoCmsHome {
      id
      canonical
      seoMetaTags {
        tags
      }
      newsFeedModularBlock {
        ...NewsFeedModularBlock
      }
      model {
        apiKey
      }
      sliderHero {
        id
        label
        title
        duration
        description
        colorTheme
        dateActivated
        dateDeactivated
        link
        buttonModularContent {
          labelButton {
            value
          }
        }
        mediaModularContent {
        ... on DatoCmsImageBlock {
          id
          model {
            apiKey
          }
          image {
            alt
            fluid(
              imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
              sizes: "(max-width: 1920px) 100vw, 100vw"
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          imageMobile {
            alt
            fluid(
              imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
              sizes: "(max-width: 767px) 100vw, 100vw"
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsVideoBlock {
          id
          model {
              apiKey
          }
          video {
            video {
              frameRate
              streamingUrl
              thumbnailUrl
              mp4Url
              muxPlaybackId
            }
          }
          videoMobile {
            video {
              muxPlaybackId
              frameRate
              streamingUrl
              thumbnailUrl
              mp4Url
            }
          }
          }
        }
      }
      visualComposerTop {
        ...BannerFullImage
        ...BannerIcon
        ...BannerText
        ...BannerBigImage
        ...BannerDouble
        ...Quote
        ...CenteredNumbers
        ...Numbers
        ...Spacing
        ...SliderImage
      }
      visualComposerBottom {
        ...BannerFullImage
        ...BannerIcon
        ...BannerText
        ...BannerBigImage
        ...BannerDouble
        ...Quote
        ...CenteredNumbers
        ...Numbers
        ...Spacing
        ...SliderImage
      }
    }
    datoCmsNewsArchive {
      slug
    }
    allDatoCmsNews(limit: 9, sort: {fields: publicationDate, order: DESC}) {
      nodes {
        id
        paragraph
        title
        slug
        publicationDate
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
            sizes: "(max-width: 1920px) 300px"
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        format {
          name
          slug
        }
        topic {
          slug
          name
          categoryNews {
            name
            slug
          }
        }
      }
    }
    datoCmsCommunicationBar {
      id
      title
      isActive
      model {
        apiKey
      }
      banner {
        ... on DatoCmsDonationBar {
          model {
            apiKey
          }
          phone
        }
        ... on DatoCmsGeneralBar {
          model {
            apiKey
          }
          url
        }
      }
    }
  }
`;
