/* eslint-disable react/jsx-boolean-value */
import React, {
  useLayoutEffect,
  useRef,
  useState,
  useEffect
} from 'react';
import Img from 'gatsby-image';
import { ButtonLink } from '../Link';
import Linked from '../Linked';
// import { useCreateSourceHeaderImage } from '../../hooks/archive-hooks';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const HeroSlide = ({
  buttonModularContent,
  mediaModularContent,
  activeSlide,
  description,
  colorTheme,
  duration,
  label,
  index,
  title,
  link
}) => {
  const { labelButton } = buttonModularContent;
  const { model } = mediaModularContent;
  const [theme, setTheme] = useState('brand');
  const [colorButton, setColorButton] = useState('brand-on-black');

  const displayMedia = () => {
    if (model.apiKey === 'video_block') {
      const { video, videoMobile } = {
        video: mediaModularContent.video.video,
        videoMobile: mediaModularContent.videoMobile.video
      };
      return (
        <Video src={video.mp4Url} srcMobile={videoMobile ? videoMobile.mp4Url : ''} activeSlide={activeSlide} index={index} />
      );
    }
    const {
      image,
      imageMobile
    } = {
      image: mediaModularContent.image,
      imageMobile: mediaModularContent.imageMobile
    };
    return (
      <>
        <Img
          fluid={image.fluid}
          alt={image.alt}
          loading="eager"
          className={`hero__slide-image hero__slide-image--standard ${imageMobile === null ? 'hero__slide-image--both' : ''}`}
        />
        {
          imageMobile && (
            <Img
              fluid={imageMobile.fluid}
              alt={image.alt}
              loading="eager"
              className="hero__slide-image hero__slide-image--mobile"
            />
          )
        }
      </>
    );
  };

  const handleGaEventDonation = (url) => {
    if (url.includes('donazioni.unicef.it')) {
      useGaEvent('Donations', 'Donate Now');
    }
  };

  useEffect(() => {
    if (colorTheme === 'giallo') {
      setTheme('yellow');
      setColorButton('yellow-on-black');
    } else {
      setTheme('brand');
      setColorButton('brand-on-black');
    }
  }, []);

  return (
    <div className={`swiper-slide hero__slide hero__slide-${theme}`} data-swiper-autoplay={duration}>
      <div className="hero__slide-content">
        <div className="hero__slide-content-mask" />
        <div className="hero__slide-media-container">
          {displayMedia()}
        </div>
        <div className="hero__inner-container">
          <div className="hero__inner-content">
            <span className={`hero__label label text-color-${theme}`}>{label}</span>
            {
              link ? (
                <div className="hero__title-container">
                  <Linked
                    className="hero__title-link"
                    url={link}
                    onClick={() => handleGaEventDonation(link)}
                  >
                    <h2 className="hero__title heading-xl text-color-white">{title}</h2>
                  </Linked>
                </div>
              ) : (
                <div className="hero__title-container">
                  <h2 className="hero__title heading-xl text-color-white">{title}</h2>
                </div>
              )
            }
            {description && <p className="hero__description paragraph text-color-white">{description}</p>}
            {
              link && (
                <ButtonLink
                  url={link}
                  kind="primary"
                  color={colorButton}
                  className="hero__btn"
                  onClick={() => handleGaEventDonation(link)}
                  label={`${labelButton ? labelButton.value : 'Scopri di più'}`}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const Video = ({
  src, srcMobile, activeSlide, index
}) => {
  const videoRef = useRef(null);

  const restartVideo = () => {
    videoRef.current.currentTime = 0;
  };

  useLayoutEffect(() => {
    const isVideoActive = index === activeSlide;
    if (isVideoActive) {
      restartVideo();
    }
  }, [activeSlide]);
  return (
    <>
      <video
        muted
        className="hero__slide-video hero__slide__video--desktop"
        ref={videoRef}
        height="100%"
        width="100%"
        src={src}
        autoPlay
        playsInline
        loop
      />
      {
        srcMobile && (
          <video
            muted
            className="hero__slide-video hero__slide__video--mobile"
            ref={videoRef}
            height="100%"
            width="100%"
            src={srcMobile}
            autoPlay
            playsInline
            loop
          />
        )
      }
    </>
  );
};

HeroSlide.defaultProps = {
  mediaModularContent: [],
  buttonModularContent: [],
  description: undefined,
  label: undefined
};

export default HeroSlide;
