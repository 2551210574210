/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect
} from 'react';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';

gsap.registerPlugin(CSSRulePlugin);

const ProgressBar = ({
  indexBar,
  activeSlide,
  swiper,
  duration
}) => {
  const [timeline, setTimeline] = useState(null);
  const runner = useRef(null);

  useEffect(() => {
    if (timeline) {
      // When the counter restarts all the progress bars are setted to zero.
      if (activeSlide === 0) {
        timeline.to(runner.current, { scaleX: 0, duration: 0 });
      }
      // Starting the animation when the counter is equals to the index of the progress bar.
      if (activeSlide === indexBar) {
        timeline.fromTo(runner.current, { scaleX: 0 }, { scaleX: 1, duration });
      }
      if (activeSlide > indexBar) {
        timeline.clear();
        timeline.to(runner.current, { scaleX: 1, duration: 0 });
      } else if (activeSlide < indexBar) {
        timeline.clear();
        timeline.to(runner.current, { scaleX: 0, duration: 0 });
      }
    }
  }, [timeline, activeSlide]);

  useLayoutEffect(() => {
    if (runner.current) {
      const tl = gsap.timeline();
      // Setting to zero all the timeline on startup
      tl.to(runner.current, { scaleX: 0, duration: 0 });
      setTimeline(tl);
    }
  }, []);

  // Handling display block/none on mobile by className
  const isActive = activeSlide === indexBar;
  const progressBarClasses = `hero__progress-bar ${isActive ? 'hero__progress-bar--active' : ''}`;
  return (
    <div className={progressBarClasses} onClick={() => swiper.slideTo(indexBar + 1)}>
      <div ref={runner} className="hero__progress-runner" />
    </div>
  );
};

export default ProgressBar;
