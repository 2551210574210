import React from 'react';
import Img from 'gatsby-image';
import { useCreateSourceHeaderImage } from '../../hooks/archive-hooks';

const EmergencyHero = ({
  title,
  titleMobile,
  titlePosition,
  titleSize,
  image,
  imageMobile
}) => {
  const dictionaryPosition = {
    top: 'top',
    bottom: 'bottom',
    center: 'center',
  };
  const dictionarySize = {
    large: 'large',
    small: 'small',
  };
  const position = dictionaryPosition[titlePosition];
  const size = dictionarySize[titleSize];
  return (
    <>
      <div className={`emergency-hero emergency-hero--text-${position}`}>
        {image && (
          <div className="emergency-hero__image-container">
            <Img
              fluid={useCreateSourceHeaderImage(image, imageMobile)}
              className="emergency-hero__image"
              alt={image.alt}
            />
          </div>
        )}

        <div className="emergency-hero__title-container">
          <h3 className={`emergency-hero__title--desktop emergency-hero__title--${size}`}><span className="emergency-hero__title--desktop-underline">{title}</span></h3>
          <h3 className={`emergency-hero__title--mobile emergency-hero__title--${size}`}><span className="emergency-hero__title--mobile-underline">{titleMobile}</span></h3>
        </div>
      </div>
    </>
  );
};

export default EmergencyHero;
