/* eslint-disable */
import React, {
  useState, useLayoutEffect, useRef
} from 'react';
import moment from 'moment';
import Swiper, { Autoplay, EffectFade } from 'swiper';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import 'swiper/swiper-bundle.css';
import SliderHeroProgressBar from '../SliderHeroProgressBar';
import HeroSlide from '../HeroSlide';

gsap.registerPlugin(CSSRulePlugin);

const swiperOptions = {
  slidesPerView: 1,
  // initialSlide: 0,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
    waitForTransition: false
  },
  fadeEffect: {
    crossFade: true
  },
  effect: 'fade',
  speed: 1000,
  grabCursor: true,
  initialSlide: 0,
  loop: false,
  updateOnWindowResize: true
};

const HeroSlider = ({ slides }) => {
  const [mySwiper, setMySwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const swiperContainer = useRef(null);

  const isActivated = (slide) => {
    const {
      dateActivated,
      dateDeactivated
    } = slide;

    if (dateActivated && dateDeactivated) {
      return !!((dateActivated && moment().isBetween(dateActivated, dateDeactivated)));
    }
    if (dateActivated) {
      return !!((dateActivated && moment().isAfter(dateActivated)));
    }
    if (dateDeactivated) {
      return !!((dateDeactivated && moment().isBefore(dateDeactivated)));
    }
    return true;
  };

  const slidesTemp = slides.filter(isActivated);

  const counter = `${activeIndex} / ${slidesTemp.length}`;

  useLayoutEffect(() => {
    if (swiperContainer.current && mySwiper === null && slidesTemp.length >= 1) {
      Swiper.use([Autoplay, EffectFade]);
      const tl = gsap.timeline();
      const swiper = new Swiper(swiperContainer.current, {
        ...swiperOptions,
        on: {
          slideChange: (swiper) => { // OLD SETTING
            const { activeIndex, slides } = swiper;
            //const realIndex = +slides[activeIndex].getAttribute('data-swiper-slide-index') + 1;
            setActiveIndex(activeIndex + 1);
          }
        }
      });
      setMySwiper(swiper);
      setTimeout(() => {
        swiper.updateSlidesClasses();
      }, 200);
    }
    return () => {
      if (mySwiper) {
        mySwiper.destroy(true, true)
      }
    }
  }, []);

  return (
    <section className="hero">
      <div className="hero__container">
        <div className="hero__slider">
          <div className="hero__slide-content-mask" />
          <div ref={swiperContainer} className="swiper-container">
            <div className="swiper-wrapper">
              {slidesTemp && slidesTemp.map((slide, index) => {
                const {
                  id, duration, link, title, mediaModularContent, description, label, buttonModularContent, colorTheme
                } = slide;
                return (
                  <HeroSlide
                    key={id}
                    title={title}
                    description={description}
                    label={label}
                    link={link}
                    index={index}
                    slide={slide}
                    mediaModularContent={mediaModularContent[0]}
                    buttonModularContent={buttonModularContent[0]}
                    colorTheme={colorTheme}
                    activeSlide={activeIndex - 1}
                    duration={duration * 1000}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="hero__background">
          {
            slidesTemp.length > 1 && (
              <div className="hero__bottom-container">
                <div className="hero__bottom">
                  {slidesTemp && slidesTemp.map((slide, index) => {
                    // The amount of bars to display is related to the number of slides
                    const { id, duration } = slide;
                    return (
                      <SliderHeroProgressBar
                        key={id}
                        swiper={mySwiper}
                        indexBar={index}
                        activeSlide={activeIndex - 1}
                        duration={duration}
                      />
                    );
                  })}
                </div>
                <span className="hero__current-slide label-small text-color-white">
                  {counter}
                </span>
              </div>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default HeroSlider;
