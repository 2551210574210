import React from 'react';
import logo from '../../assets/images/logo-brand-h.svg';
import Icon from '../Icons';

const EmergencyHeader = ({ closeVerticalSlider }) => {
  return (
    <div className="emergency-header__wrapper">
      <div className="emergency-header__logo-wrapper">
        <figure className="emergency-header__figure">
          <img src={logo} alt="Emergenza logo di Unicef" className="emergency-header__logo" />
        </figure>
      </div>
      <div className="emergency-header__button-wrapper">
        <span className="emergency-header__button-label">Torna al sito Unicef</span>
        <button
          type="button"
          className="emergency-header__button"
          onClick={() => closeVerticalSlider()}
        ><Icon path="arrow-up-white" />
        </button>
      </div>
    </div>
  );
};

export default EmergencyHeader;
