import React from 'react';
import EmergencyTextWithDescription from '../EmergencyTextWithDescription';

const EmergencyTextWithAudio = ({
  title,
  description,
  audio,
  caption,
  date
}) => {
  return (
    <div className="emergency-text-with-audio__grid">
      <div className="emergency-text-with-audio__wrapper">
        {title && (
          <div className="emergency-text-with-audio__text-container">
            <EmergencyTextWithDescription title={title} description={description} />
          </div>
        )}
        <div className="emergency-text-with-audio__audio-container">
          <figure>
            <audio controls className="emergency-text-with-audio__panel">
              <track default kind="captions" srcLang="it" src={audio} />
              <source src={audio} type="audio/mpeg" />
              <source src={audio} type="audio/ogg" />
              <a href={audio}> Download audio </a>
            </audio>
            {caption && <figcaption className="emergency-text-with-audio__caption">{caption}{date && <><br /><span className="emergency-text-with-audio__date">{date.split('/').join(' ')}</span></>}</figcaption>}
          </figure>
        </div>
      </div>
    </div>
  );
};

export default EmergencyTextWithAudio;
