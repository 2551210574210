import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Mousewheel,
  Navigation,
  Pagination,
  Keyboard
} from 'swiper/core';
import EmergencyStripe from '../EmergencyStripe';
import EmergencyHero from '../EmergencyHero';
import EmergencyTextWithImage from '../EmergencyTextWithImage';
import EmergencyTextWithGallery from '../EmergencyTextWithGallery';
import EmergencyTextWithVideo from '../EmergencyTextWithVideo';
import EmergencyHeader from '../EmergencyHeader';
import EmergencyTextWithAudio from '../EmergencyTextWithAudio';
import Quote from '../Quote';
import Icon from '../Icons';
import useMatchMedia from '../../hooks/match-media';

SwiperCore.use([Pagination, Mousewheel, Navigation, Keyboard]);

const EmergencyDonationWidget = () => {
  return (
    <div id="divDonazione">
      <div ng-app="donazioni03">
        <div id="wdn" ng-controller="initController" ng-view="true"> </div>
      </div>
    </div>
  );
};

const EmergencyVerticalSlider = ({
  stripe,
  hero,
  hasForm,
  colorTheme,
  modularContent
}) => {
  const [isOpenEmergencySlider, setisOpenEmergencySlider] = useState(false);
  const dictionaryTheme = {
    rosso: 'red',
    azzurro: 'brand'
  };

  const theme = dictionaryTheme[colorTheme];

  const [isScroll, setIsScroll] = useState(false);
  const [showWidget, setShowWidget] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const MAX_WIDTH_MOBILE = '(max-width: 1023px)';
  const MIN_WIDTH_DESKTOP = '(min-width: 1024px)';

  useMatchMedia(setIsMobile, MAX_WIDTH_MOBILE);
  useMatchMedia(setIsDesktop, MIN_WIDTH_DESKTOP);

  const isBrowser = typeof window !== 'undefined';

  const closeVerticalSlider = () => {
    setisOpenEmergencySlider(false);
  };

  const handleNavigation = useCallback(
    () => {
      if (isBrowser) {
        const isMatchedMedia = window.matchMedia('(min-width: 1366px)').matches;
        const scrollTop = window.scrollY;
        if (isMatchedMedia && scrollTop >= 400) {
          setIsScroll(true);
        } else if (isMatchedMedia && scrollTop < 400) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      }
    },
  );

  const handleScrollMobile = () => {
    const widgetStatic = document.querySelector('.widget--static');
    const widgetStaticRect = widgetStatic.getBoundingClientRect();
    const widgetContainer = document.querySelector('.widget__container');

    if (widgetStatic && widgetStaticRect.bottom.toFixed() < -100) {
      widgetContainer.classList.add('widget--small-size');
      widgetStatic.classList.add('widget--dynamic-size');
    } else {
      widgetContainer.classList.remove('widget--small-size');
      widgetStatic.classList.remove('widget--dynamic-size');
    }
  };

  const loadVerticalSlider = () => {
    const widget = document.querySelector('.widget__container');
    if (isBrowser) {
      widget.classList.remove('widget--small-size');
      return (
        setisOpenEmergencySlider(true)
      );
    }
  };

  const closeWidget = () => {
    setShowWidget(false);
  };

  useEffect(() => {
    loadVerticalSlider();
  }, []);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', handleNavigation);

      return () => {
        window.removeEventListener('scroll', handleNavigation);
      };
    }
  }, [handleNavigation]);

  useEffect(() => {
    if (isOpenEmergencySlider) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpenEmergencySlider]);

  useEffect(() => {
    if (isDesktop || (isMobile && showWidget)) {
      const emergencyWidgetFormContainer = document.querySelector('.widget__inner-container');
      const emergencyWidgetForm = document.getElementById('divDonazione');
      emergencyWidgetFormContainer.appendChild(emergencyWidgetForm);
    } else if (isMobile && !showWidget) {
      const emergencyWidgetFormStaticContainer = document.querySelector('.widget--static');
      const emergencyWidgetForm = document.getElementById('divDonazione');
      emergencyWidgetFormStaticContainer.appendChild(emergencyWidgetForm);
    }
  }, [showWidget, isMobile, isDesktop]);

  return (
    <div className={`emergency-vertical-slider--color-${theme} ${isOpenEmergencySlider ? 'emergency-vertical-slider--fixed' : 'emergency-vertical-slider'} ${isScroll ? 'emergency-vertical-slider--scrolling' : ''}`}>
      <div className="emergency-vertical-slider__stripe-wrapper">
        <EmergencyStripe><span className="emergency-vertical-slider__stripe-label">{stripe}</span></EmergencyStripe>
        <button className="emergency-vertical-slider__stripe-cta" type="button" onClick={() => setisOpenEmergencySlider(true)}> </button>
      </div>

      <div className="emergency-vertical-slider__wrapper">
        <div className="emergency-vertical-slider__header-wrapper">
          <EmergencyHeader closeVerticalSlider={() => closeVerticalSlider()} />
        </div>
        {hasForm && (
          <div className={`widget__container widget--small-size ${showWidget ? 'widget__container--fullscreen' : ''}`}>
            <div className="widget__grid">
              <div className="widget">
                <button
                  type="button"
                  className="widget__icon-close"
                  onClick={() => closeWidget()}
                >
                  <Icon className="icon--24" path="close" />
                </button>
                <div className="widget__inner-container">
                  <EmergencyDonationWidget />
                </div>
                <button type="button" onClick={() => setShowWidget(true)} className="widget__button">DONA ORA</button>
              </div>
            </div>
          </div>
        )}
        <div className="navigation-container">
          <div className="swiper-button-prev-vertical-slider"> </div>
          <div className="swiper-button-next-vertical-slider"> </div>
        </div>
        <Swiper
          onSliderMove={() => hasForm && handleScrollMobile()}
          touchEventsTarget="container"
          direction="vertical"
          slidesPerView="auto"
          pagination={{
            type: 'bullets',
            clickable: true,
            el: '.swiper-pagination-vertical-slider',

          }}
          speed={400}
          className={`swiper-v ${hasForm ? 'swiper-with-form' : ''}`}
          freeMode
          updateOnWindowResize
          watchOverflow
          mousewheel
          watchSlidesVisibility
          slidesOffsetAfter={hasForm ? 100 : 0}
          autoHeight
          spaceBetween={40}
          breakpoints={
            {
              1024: {
                slidesPerView: 1,
                allowTouchMove: false,
                simulateTouch: false,
                keyboard: { enabled: true },
                freeMode: false,
                autoHeight: false,
                spaceBetween: 0,
                navigation: {
                  nextEl: '.swiper-button-next-vertical-slider',
                  prevEl: '.swiper-button-prev-vertical-slider',
                },
                slidesOffsetAfter: 0
              }
            }
          }
        >
          <div className="pagination-container">
            <div className="swiper-pagination-vertical-slider"> </div>
          </div>
          <SwiperSlide key={0} className="emergency-hero__slide">
            <EmergencyHero {...hero} />
            {hasForm && (
              <div className="widget--static"> </div>
            )}
          </SwiperSlide>
          {modularContent.map((block, index) => {
            const key = index + 1;
            switch (block.model.apiKey) {
              case 'emergency_text_with_image_block':
                return (
                  <SwiperSlide key={key}>
                    <EmergencyTextWithImage
                      title={block.title}
                      description={block.description}
                      image={block.image}
                      caption={block.caption}
                      date={block.date}
                    />
                  </SwiperSlide>
                );
              case 'emergency_text_with_gallery_block':
                return (
                  <SwiperSlide key={key}>
                    <EmergencyTextWithGallery
                      title={block.title}
                      description={block.description}
                      images={block.imageGallery}
                    />
                  </SwiperSlide>
                );
              case 'emergency_text_with_audio_block':
                return (
                  <SwiperSlide key={key}>
                    <EmergencyTextWithAudio
                      title={block.title}
                      description={block.description}
                      audio={block.audio.url}
                      caption={block.caption}
                      date={block.date}
                    />
                  </SwiperSlide>
                );
              case 'emergency_text_with_video_block':
                return (
                  <SwiperSlide key={key}>
                    <EmergencyTextWithVideo
                      title={block.title}
                      description={block.description}
                      video={block.video.providerUid}
                      coverImage={block.previewImage}
                      caption={block.caption}
                      date={block.date}
                    />
                  </SwiperSlide>
                );
              case 'emergenza_quote':
                return (
                  <SwiperSlide key={key}>
                    <Quote
                      isEmergency
                      title={block.title}
                      description={block.description}
                      image={block.imageDesktop}
                      imageMobile={block.imageMobile}
                    />
                  </SwiperSlide>
                );
              default:
                return null;
            }
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default EmergencyVerticalSlider;

EmergencyVerticalSlider.defaultProps = {
  colorTheme: 'rosso',
  hasForm: true,
  stripe: 'Emergenza in Siria',
  hero: {
    titleSize: 'small',
    titlePosition: 'bottom',
    title: 'Test',
    titleMobile: 'Test',
    image: {
      fluid: {
        src: 'https://www.w3schools.com/html/pic_trulli.jpg'
      }
    },
    imageMobile: {
      fluid: {
        src: 'https://www.w3schools.com/html/img_chania.jpg'
      }
    }
  },
  textWithImage: {
    title: 'Da ormai più di un anno la guerra in Ucraina sconvolge la vita di oltre 7,8 milioni di bambini.',
    description: 'Noi continuiamo a lavorare 24 ore al giorno per stare al loro fianco, la guerra però non si è conclusa e i bambini hanno ancora bisogno del nostro aiuto.',
    image: {
      fluid: {
        src: 'https://www.datocms-assets.com/30196/1684402973-un0612164_lanciomedio5x1000-2x.jpg?auto=compress&dpr=1&fit=max&fm=jpg&w=3200',
        alt: 'lorem ipsum'
      },
    },
    caption: 'Lorem ipsum dolor sit amet',
    date: '21 Mar, 2023'
  },
  textWithGallery: {
    title: 'Da ormai più di un anno la guerra in Ucraina sconvolge la vita di oltre 7,8 milioni di bambini.',
    description: 'Noi continuiamo a lavorare 24 ore al giorno per stare al loro fianco, la guerra però non si è conclusa e i bambini hanno ancora bisogno del nostro aiuto.',
    images: [
      {
        fluid: {
          src: 'https://www.w3schools.com/html/pic_trulli.jpg'
        }
      },
      {
        fluid: {
          src: 'https://www.w3schools.com/html/pic_trulli.jpg'
        }
      },
      {
        fluid: {
          src: 'https://www.w3schools.com/html/pic_trulli.jpg'
        }
      }
    ]
  },
  textWithVideo: {
    title: 'Da ormai più di un anno la guerra in Ucraina sconvolge la vita di oltre 7,8 milioni di bambini.',
    description: 'Noi continuiamo a lavorare 24 ore al giorno per stare al loro fianco, la guerra però non si è conclusa e i bambini hanno ancora bisogno del nostro aiuto.',
    video: {
      title: 'test titolo video',
      url: 'watch?v=gTcnHXApGD4&t=3s'
    },
    coverImage: {
      fluid: {
        src: 'https://www.datocms-assets.com/30196/1684402973-un0612164_lanciomedio5x1000-2x.jpg?auto=compress&dpr=1&fit=max&fm=jpg&w=3200',
        alt: 'lorem ipsum'
      }
    },
    caption: 'Lorem ipsum dolor sit amet',
    date: '21 Mar, 2023'
  },
  textWithAudio: {
    title: 'Da ormai più di un anno la guerra in Ucraina sconvolge la vita di oltre 7,8 milioni di bambini.',
    description: 'Noi continuiamo a lavorare 24 ore al giorno per stare al loro fianco, la guerra però non si è conclusa e i bambini hanno ancora bisogno del nostro aiuto.',
    audio: 'https://www.datocms-assets.com/30196/1694600395-file_example_mp3_700kb.mp3',
    caption: 'Lorem ipsum dolor sit amet',
    date: '21 Mar, 2023'
  },
  quote: {
    image: {
      fluid: {
        src: 'https://www.w3schools.com/html/pic_trulli.jpg'
      }
    },
    imageMobile: {
      fluid: {
        src: 'https://www.w3schools.com/html/img_chania.jpg'
      }
    },
    title: "L’accesso all'istruzione rimane in cima alle nostre priorità, soprattutto per quanto riguarda gli adolescenti nei campi profughi.",
    description: '<strong>Christophe Boulierac,</strong> portavoce UNICEF a Ginevra',
  }
};
