import React from 'react';
import EmergencyTextWithDescription from '../EmergencyTextWithDescription';
import VideoEmbed from '../VideoEmbed';

const EmergencyTextWithVideo = ({
  title,
  description,
  video,
  coverImage,
  caption,
  date
}) => {
  return (
    <div className="emergency-text-with-video__grid">
      <div className="emergency-text-with-video__wrapper">
        {video && (
          <VideoEmbed
            hasDefaultLayout
            marginTop="zero"
            marginBottom="zero"
            image={coverImage}
            url={video}
          />
        )}
        {caption && <div className="emergency-text-with-video__caption">{caption}{date && <><br /><span className="emergency-text-with-video__date">{date.split('/').join(' ')}</span></>}</div>}
        {title && (
          <div className="emergency-text-with-video__text-container">
            <EmergencyTextWithDescription title={title} description={description} />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmergencyTextWithVideo;
