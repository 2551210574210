import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import Img from 'gatsby-image';
import EmergencyTextWithDescription from '../EmergencyTextWithDescription';

SwiperCore.use([Pagination, Navigation]);

const EmergencyTextWithGallery = ({
  title,
  description,
  images
}) => {
  return (
    <div className="emergency-text-with-gallery__wrapper">
      <div className="emergency-text-with-gallery__grid">
        {title && (
          <div className="emergency-text-with-gallery__description">
            <EmergencyTextWithDescription title={title} description={description} />
          </div>
        )}

        {images && (
          <div className="emergency-text-with-gallery__slider">
            <Swiper
              centeredSlides
              spaceBetween={8}
              initialSlide={0}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                  slidesPerGroup: 1
                },
              }}
              pagination={{
                type: 'fraction',
              }}
              navigation
              className="swiper-h"
            >
              {images.map((image, index) => {
                const { fluid, alt, originalId } = image;
                return (
                  <SwiperSlide key={`${originalId}-${index}`}>
                    <div className="emergency-text-with-gallery__image">
                      <Img fluid={{ ...fluid, aspectRatio: 1.68 }} alt={alt} loading="eager" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmergencyTextWithGallery;
