import React from 'react';
import Marquee from 'react-fast-marquee';
import Icon from '../Icons';

const EmergencyStripe = ({ children }) => {
  return (
    <div className="emergency-stripe__wrapper">
      <Marquee
        speed={150}
        loop={0}
        className="emergency-stripe"
        autoFill={48}
        play
      >
        {children}
      </Marquee>
      <div className="emergency-stripe__icon-wrapper">
        <Icon path="arrow-up-white" className="emergency-stripe__icon" />
      </div>
    </div>
  );
};

export default EmergencyStripe;
